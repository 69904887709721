@import url(/Asset/fonts/AppleTsukuBRdGothic-Bold.min.css);
@import url(/Asset/GenSenMaruGothicTW-Regular.ttf.font.min.css);
@font-face {
  font-family: "FaSolid";
  src: 
    url("/Asset/fonts/fa-solid-900.woff2") format("woff2"),
    url("/Asset/fonts/fa-solid-900.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body ,html, #root {
    background-color: black;
}
#loadingoverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #212121;
    opacity: 1;
    transition: opacity 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

#loadingoverlay.hideloadingoverlay {
    display: none;
}

@-webkit-keyframes loadinghop {
    0% {
        -webkit-transform: translateY(5vh) scaleX(0.52) scaleY(0.5);
                transform: translateY(5vh) scaleX(0.52) scaleY(0.5);
    }
    35% {
        -webkit-transform: translateY(-5vh) scaleX(0.5) scaleY(0.53);
                transform: translateY(-5vh) scaleX(0.5) scaleY(0.53);
    }
    70% {
        -webkit-transform: translateY(5vh) scaleX(0.52) scaleY(0.5);
                transform: translateY(5vh) scaleX(0.52) scaleY(0.5);
    }
    100% {
        -webkit-transform: translateY(5vh) scaleX(0.53) scaleY(0.51);
                transform: translateY(5vh) scaleX(0.53) scaleY(0.51);
    }
}

@keyframes loadinghop {
    0% {
        -webkit-transform: translateY(5vh) scaleX(0.52) scaleY(0.5);
                transform: translateY(5vh) scaleX(0.52) scaleY(0.5);
    }
    35% {
        -webkit-transform: translateY(-5vh) scaleX(0.5) scaleY(0.53);
                transform: translateY(-5vh) scaleX(0.5) scaleY(0.53);
    }
    70% {
        -webkit-transform: translateY(5vh) scaleX(0.52) scaleY(0.5);
                transform: translateY(5vh) scaleX(0.52) scaleY(0.5);
    }
    100% {
        -webkit-transform: translateY(5vh) scaleX(0.53) scaleY(0.51);
                transform: translateY(5vh) scaleX(0.53) scaleY(0.51);
    }
}

#loadingoverlayimg {
    max-width: 78vw;
    -webkit-animation: loadinghop 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
            animation: loadinghop 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
}

.MuiSvgIcon-root {
    vertical-align: middle !important;
    padding-bottom: 3px;
}

a {
    text-decoration: none;
    color: black;
    transition: color 0.2s;
}

a:visited {
    text-decoration: none;
    color: black;
}

.gridListTile:hover .gridImg {
    opacity: 0;
}

.mdContent {
    font-family: '筑紫B丸ゴシック ボールド', 'GenSenMaruGothicTW', 'Noto Sans TC', sans-serif;
}

.mdContent a::after {
    content: ' \f0c1';
    font-family: 'FaSolid';
    font-weight: 400;
    text-rendering: auto;
    font-size: 80%;
}

.mdContent a:hover {
    color: #4fbece;
}

.bannerContent {
    font-size: 19px;
}

@media screen and (max-width: 700px) {
    .bannerContent {
        font-size: 10.5px;
    }
}

.mdContent.aboutContent {
    text-align: justify;
    text-justify: inter-character;
}

.mdContent.aboutContent {
    padding-right: calc(22% + 16px);
}
.mdContent.contactContent a:after {
    content: none;
}
.mdContent.contactContent a {
    border: 3px solid rgba(0,0,0,0);
    color: #212121;
    padding: 0.3em;
    transition: border 200ms;
}
.mdContent.contactContent a:hover {
    border-color: #212121;
}

.aboutContent img {
    float: left;
    max-width: 30%;
    clear: left;
    margin-right: -30%;
    position: relative;
    right: -100%;
}

.hideScrollBar::-webkit-scrollbar {
    display: none;
}

.hideScrollBar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hideOnDesktop {
    display: none;
}
.hamburgerBar {
    top: 0;
    width: 100%;
    text-align: left;
    padding: 0.5em;
    z-index: 2000;
    margin-bottom: -42px;
}
.hamburgerIcon {
    color: white;
    font-size: 22pt;
}
.hamburgerBar.triggered .hamburgerIcon{
    color: black;
}

@media screen and (max-width: 700px) {
    .mdContent.aboutContent {
        padding-right: 16px;
    }
    .aboutContent img {
        float: none;
        max-width: 100%;
        clear: both;
        margin-right: auto;
        position: relative;
        right: auto;
    }
    div.wide-inner-wrapper {
        flex-direction: column;
    }
    div.wide-inner-wrapper img {
        width: 100% !important;
    }
    .hideOnDesktop {
        display: block;
    }
    .hideOnMobile {
        display: none !important;
    }
}

@-webkit-keyframes movingBackground {
    0% {
        background-position: 0px 0px;
        background-size: 0%;
    }
    100% {
        background-position: 0px -330px;
        background-size: 100%;
    }
}

@keyframes movingBackground {
    0% {
        background-position: 0px 0px;
        background-size: 0%;
    }
    100% {
        background-position: 0px -330px;
        background-size: 100%;
    }
}
body, html, #root{
    overflow: auto;
    max-height: auto;
}
